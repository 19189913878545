import Flickity from 'flickity';
import 'flickity/dist/flickity.pkgd.js';
import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';
import 'select2/dist/js/select2.min.js';
import AOS from 'aos';

// sticky header variable
var shrinkHeader = 100;

// Flickity.prototype._createResizeClass = function() {
//   this.element.classList.add('flickity-resize');
// };

// Flickity.createMethods.push('_createResizeClass');

// var resize = Flickity.prototype.resize;
// Flickity.prototype.resize = function() {
//   this.element.classList.remove('flickity-resize');
//   resize.call( this );
//   this.element.classList.add('flickity-resize');
// };

export default {
  init() {
    // JavaScript to be fired on all pages

    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {

    AOS.init();

    // JavaScript to be fired on all pages, after page specific JS is fired

    let mapsSectionWidth = checkMapWidth();

    if ( mapsSectionWidth == true) {
      $('#map-address-wrp').addClass('flickity-now');
      mapSectionSlider()
    }

    else {
      mapSectionSliderDestroy()
    }

    let wpcf7Element = document.querySelector('#motor-calculator-section .calculator-contact-form-wrp .wpcf7');

    if (wpcf7Element) {
      wpcf7Element.addEventListener( 'wpcf7invalid', function(event) {
        event.preventDefault();
        $('#motor-calculator-section').addClass('invalid-form');
      }, false );

      wpcf7Element.addEventListener( 'wpcf7mailsent', function(event) {
        event.preventDefault();
        $('#motor-calculator-section').removeClass('invalid-form');
      }, false );
    }

    // Add Apllications for Motor Calculations
    let wpcf7AccordionForm = document.querySelector('.accordion-form-section .wpcf7');

    if (wpcf7AccordionForm) {
      wpcf7AccordionForm.addEventListener( 'wpcf7invalid', function(event) {
        event.preventDefault();

        $('.accordion-form-section .accordion-item-wrp').each(function() {
          $(this).find('.faq-title').addClass('collapsed');
          $(this).find('.faq-content').removeClass('show');
        });

        $('.accordion-form-section .accordion-item-wrp').first().find('.faq-title').removeClass('collapsed');
        $('.accordion-form-section .accordion-item-wrp').first().find('.faq-content').addClass('show');
      }, false );
    }
    // Add Apllications for Motor Calculations

    // Listen for Input Number Field Changes on Calculator Page and Run Calculations, Validations
    $('#motor-calculator-section input').on('keyup change', function() {
      motorCalculator();
      // $(this).valid();
    });

    // Run Calculations in Calculations Page on Page Load
    $('.motor-items-wrp .motor-item').each(function() {
      // let test = $(this);
      // motorListingCalculator(test);
    });

    // Insights & News Load More
    $(document).ready(function () {
      $('.insights-item-v1').slice(0, 1).show();
      $('.insights-item-v2').slice(0, 1).show();
      if ($('.insights-item-v1:hidden').length != 0 || $('.insights-item-v2:hidden').length != 0) {
        $('#loadMoreInsights').show();
      } else {
        $('#loadMoreInsights').hide();
        $('#loadMoreInsights').text('').fadeOut('slow');
        $('#loadMoreInsights').addClass('load-more-end');
      }
      $('#loadMoreInsights').on('click', function (e) {
        e.preventDefault();
        $('.insights-item-v1:hidden').slice(0, 1).slideDown();
        $('.insights-item-v2:hidden').slice(0, 1).slideDown();
        if ($('.insights-item-v1:hidden').length == 0) {
          $('#loadMoreInsights').text('No More to view').fadeOut('slow');
          $('#loadMoreInsights').addClass('load-more-end');
        }
      });
    });

    //map-section popup+
    $('.contact-map-section #map-address-wrp .title-wrp-outer').each(function () {
      $(this).click(function() {
        $('.map-details').slideUp();
        $(this).next('.map-details').slideDown();
      });
    });

    $('.contact-map-section .flickity-button').click(function () {
      $('.show-address .map-details').slideUp();
      $('.item-wrp').each(function () {
        $(this).removeClass('show-address');
      });
    });
    //map-section popup

    // Product Listing Button Action
    $('.product-listing-view-datasheet').click(function (e) {
      e.preventDefault();
      $(this).slideUp();
      $(this).siblings('.product-listing-view-datasheet-content').slideDown();
    });

    var wpcf7ElmListing = document.querySelectorAll( '.products-details-section .motor-items-wrp .wpcf7' );

    for (let i = 0; i <wpcf7ElmListing.length; i++) {
      wpcf7ElmListing[i].addEventListener( 'wpcf7mailsent', function() {
        window.open($(this).parent().siblings('.datasheet-link').attr('href'));
      }, false );
    }
    // Product Listing Button Action

    // Insights & News Mobile Load More
    $(document).ready(function () {
      $('.insights-item-mobile').slice(0, 2).show();
      if ($('.insights-item-mobile:hidden').length != 0) {
        $('#loadMoreInsightsMobile').show();
      } else {
        $('#loadMoreInsightsMobile').hide();
        $('#loadMoreInsightsMobile').text('').fadeOut('slow');
        $('#loadMoreInsightsMobile').addClass('load-more-end');
      }
      $('#loadMoreInsightsMobile').on('click', function (e) {
        e.preventDefault();
        $('.insights-item-mobile:hidden').slice(0, 2).slideDown();
        if ($('.insights-item-mobile:hidden').length == 0) {
          $('#loadMoreInsightsMobile').text('No More to view').fadeOut('slow');
          $('#loadMoreInsightsMobile').addClass('load-more-end');
        }
      });
    });

    // toggle class on hamburger icon click
    $('.mobile-burger-icon').click(function () {
      $('body, .mobile-burger-icon').toggleClass('show-menu');
      $('.mobile-menu .sub-menu-wrap').removeClass('show-menu');
    });

    // mobile sub menu navigation
    // level 1
    $('.mobile-menu .nav > li.menu-item-has-children').each(function () {
      $(this).append('<span class="sub-menu-click"></span>');
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
      $(this).find('> .sub-menu-click').click(function (e) {
          e.preventDefault();
          $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
        });
    });

    // level 2
    $('.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item-has-children').each(function () {
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
      $(this).find('> a').click(function (e) {
          e.preventDefault();
          $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
        });
    });

    // return link click
    $('.return-link').each(function () {
      $(this).click(function () {
        $(this).parent().parent('.sub-menu-wrap').removeClass('show-menu');
      });
    });

    // set header height to sticky overlay
    $('.sticky-overlay').outerHeight($('.header').outerHeight());


    // Cookie Policy Set up
    //check to see if the submited cookie is set, if not check if the popup has been closed, if not then display the popup
    if( getCookie('popupCookie') != 'submited'){
      if(getCookie('popupCookie') != 'closed' ){
        setTimeout(function(){$('.cookie-wrp').css('display', 'block').hide().fadeIn();}, 3000);
      }
    }

    $('a.close-cookie').click(function(){
      $('.cookie-wrp').fadeOut();
      //sets the coookie to one minute (.00069444444) if the popup is closed (whole numbers = days)
      setCookie( 'popupCookie', 'closed', 1 );
    });

    $('a.accept-cookie').click(function(){
      $('.cookie-wrp').fadeOut();
      //sets the coookie to five minutes (.0034722222 ) if the popup is submited (whole numbers = days)
      setCookie( 'popupCookie', 'submited', 100);
    });
    // Cookie Policy Set up

    motorCalculator();
    formScripts();
    cardSectionGridSlider();
    cardSectionSlider();
    cardSectionTimeline();
    gallerySectionSlider();
    jqueryViewer('#gallery-images-viewer-v2');
    jqueryViewer('#gallery-images-viewer-v1');
    fullWidthVideoPlayer();
    videoPopupPlayer();
    slideUpMenuOption();
    footerTicker();
    productSectionGridSlider();
    productSectionSlider();
    checkSectionsForGaps();
    productListingItem()
    logoSectionGridSlider();
    logoSectionSlider();
  },
};

$(window).on('resize', function () {
  let mapSectionWidthResize = checkMapWidth();

  if ( mapSectionWidthResize == true) {
    $('#map-address-wrp').addClass('flickity-now');
    mapSectionSlider()
  }

  $('.cards-section.v1 .cards-wrp, .cards-section.v2 .cards-wrp, .cards-section.v3 .cards-wrp, .products-section.v1 .products-wrp, .products-section.v2 .products-wrp, .gallery-section.v1 .gallery-wrapper, .products-details-section .product-carousel-nav, .products-details-section .product-nav, .logo-section .logo-grid, .logo-section .logo-slider').flickity('resize');
});

$(window).on('load', function () {
  $('.cards-section.v1 .cards-wrp, .cards-section.v2 .cards-wrp, .cards-section.v3 .cards-wrp, .products-section.v1 .products-wrp, .products-section.v2 .products-wrp, .gallery-section.v1 .gallery-wrapper, .products-details-section .product-carousel-nav, .products-details-section .product-nav, .logo-section .logo-grid, .logo-section .logo-slider').flickity('resize');
});

//map slider
function mapSectionSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.contact-map-section .main-carousel.flickity-now'
  ).flickity({
    cellAlign: cellAlignValue,
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    initialIndex: 2,
  });
}

function mapSectionSliderDestroy() {
  $('.contact-map-section .main-carousel.flickity-now').flickity('destroy');
}

// sticky menu
$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }

  $('.sticky-overlay').outerHeight($('.header').outerHeight());
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}
// sticky menu

function formScripts() {
  $('.form-dropdown').select2({
    dropdownParent: $('.select2-wrapper'),
  });

  $('.button-wrp-outter').append($('.wpcf7-response-output'));
}

function cardSectionGridSlider() {
  $('.cards-section.v1 .section-inner-wrp .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}

function cardSectionSlider() {
  $('.cards-section.v2').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.cards-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });

  $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
  $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
});
}

function cardSectionTimeline() {
  $('.cards-section.v3').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.cards-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: false,
      cellAlign: cellAlignValue,
      groupCells: 1,
      contain: true,
    });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function productSectionGridSlider() {
  $('.products-section.v1 .section-inner-wrp .products-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}

function productSectionSlider() {
  $('.products-section.v2').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.products-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function gallerySectionSlider() {
  $('.gallery-section.v1').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.gallery-wrp-slider').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function productListingItem() {

  var counter = 0;
  $('.products-details-section .sliders-wrp').each(function(){
    var productNavClassName = '.product-nav-'+ counter;
    var mainCarousel = $(this).find(productNavClassName);
    var asNavCarousel =  $(this).find('.product-carousel-nav-'+ counter);

    // 1st carousel, main
    $(mainCarousel).flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
    });

    // 2nd carousel, navigation
    $(asNavCarousel).flickity({
      asNavFor: productNavClassName,
      contain: true,
      pageDots: false,
      prevNextButtons: false,
    });
    counter++;
  });
}

// Implementation of Jquery Viewer
function jqueryViewer(ulId) {
  var $galleryImages = $(ulId);

  $galleryImages.viewer({
    tooltip: 0,
    inline: false,
    toolbar: {
      zoomIn: {
        show: 1,
        size: 'large',
      },
      zoomOut: {
        show: 1,
        size: 'large',
      },
      oneToOne: 0,
      reset: 0,
      prev: {
        show: 1,
        size: 'large',
      },
      play: {
        show: 0,
        size: 'large',
      },
      next: {
        show: 1,
        size: 'large',
      },
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0,
    },
    title: 0,
    viewed: function () {
      $galleryImages.viewer('zoomTo', 1);
    },
  });
}
// Implementation of Jquery Viewer

// Full Width Video Player
function fullWidthVideoPlayer() {
  for (let i = 1; i <= $('.playvideo').length ; i++) {
    let idName = 'play-video-' + i;
    let newIdName = 'video-iframe-' + i;
    $('.playvideo')[i-1].setAttribute('id', idName);
    $('.iframe-video')[i-1].setAttribute('id', newIdName);
    $('#'+idName).click(function(){
      $(this).parent('.video-wrp').addClass('video-playing');
      if ($('#'+newIdName).hasClass('video-element')) {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName).get(0).play();
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      } else {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName)[0].src += '&autoplay=1';
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      }
    });
  }
}
// Full Width Video Player

// Popup Video Player for Two Column Section
function videoPopupPlayer() {
  var $videoSrc;
  $('.video-iframe-thumbnail').click(function () {
    $videoSrc = $(this).data('src');
  });

  $('#myModal').on('shown.bs.modal', function () {
    $('#video').attr(
      'src',
      $videoSrc + '?autoplay=1&mute=1&amp;modestbranding=1&amp;showinfo=0'
    );
  });

  $('#myModal').on('hide.bs.modal', function () {
    $('#video').attr('src', $videoSrc);
  });
}
// Popup Video Player for Two Column Section

function footerTicker() {
  if ($('.footer .email-ticker-wrp')) {
    // Play with this value to change the speed
    let tickerSpeed = 1;

    let flickity = null;
    let isPaused = false;
    const slideshowEl = document.querySelector('.footer .email-ticker-wrp');

    const update = () => {
      if (isPaused) return;
      if (flickity.slides) {
        flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
        flickity.selectedIndex = flickity.dragEndRestingSelect();
        flickity.updateSelectedSlide();
        flickity.settle(flickity.x);
      }
      window.requestAnimationFrame(update);
    };

    const pause = () => {
      isPaused = true;
    };

    const play = () => {
      if (isPaused) {
        isPaused = false;
        window.requestAnimationFrame(update);
      }
    };

    flickity = new Flickity(slideshowEl, {
      autoPlay: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      selectedAttraction: 0.015,
      friction: 0.25,
    });

    flickity.x = 0;

    slideshowEl.addEventListener('mouseenter', pause, false);
    slideshowEl.addEventListener('focusin', pause, false);
    slideshowEl.addEventListener('mouseleave', play, false);
    slideshowEl.addEventListener('focusout', play, false);

    flickity.on('dragStart', () => {
      isPaused = true;
    });

    update();
  }
}

// Header Scroll up Option
function slideUpMenuOption() {
  if ($('body > .header').hasClass('enable-scroll-up')) {
    var lastScrollTop = 0, delta = 15;
    var bannerHeight = $('.header').outerHeight();
    $(window).scroll(function(){
      var st = $(this).scrollTop();

      if(Math.abs(lastScrollTop - st) <= delta)
        return;
      if ((st > lastScrollTop) && (lastScrollTop>0)) {
            // downscroll code
            $('body > .header, body > .sticky-overlay').css('top', (bannerHeight - (bannerHeight *2)));
      } else {
        // upscroll code
        $('body > .header, body > .sticky-overlay').css('top','0px');
      }
      lastScrollTop = st;
    });
  }
}
// Header Scroll up Option

function motorCalculator() {
  let botWeight = parseFloat($('.motor-calculation #bot-weight').val());
  if (botWeight < 0) {
    $('.motor-calculation #bot-weight').val('85');
    $('.motor-calculation #bot-weight').trigger('change');
  }

  let payloadWeight = parseFloat($('.motor-calculation #payload-weight').val());
  if (payloadWeight < 0) {
    $('.motor-calculation #payload-weight').val('250');
    $('.motor-calculation #payload-weight').trigger('change');
  }

  let runSpeed = parseFloat($('.motor-calculation #run-speed').val());
  if (runSpeed < 0) {
    $('.motor-calculation #run-speed').val('2');
    $('.motor-calculation #run-speed').trigger('change');
  }

  let accelerationFullLoad = parseFloat($('.motor-calculation #acceleration-full-payload').val());
  if (accelerationFullLoad < 0) {
    $('.motor-calculation #acceleration-full-payload').val('2');
    $('.motor-calculation #acceleration-full-payload').trigger('change');
  }

  let wheelDiameter = parseFloat($('.motor-calculation #wheel-diameter').val());
  if (wheelDiameter < 0) {
    $('.motor-calculation #wheel-diameter').val('0.16');
    $('.motor-calculation #wheel-diameter').trigger('change');
  }

  let casterWheels = parseFloat($('.motor-calculation #caster-wheels').val());
  if (casterWheels < 0) {
    $('.motor-calculation #caster-wheels').val('4');
    $('.motor-calculation #caster-wheels').trigger('change');
  }

  let driveWheels = parseFloat($('.motor-calculation #drive-wheels').val());
  if (driveWheels < 0) {
    $('.motor-calculation #drive-wheels').val('4');
    $('.motor-calculation #drive-wheels').trigger('change');
  }

  const gravity = 9.807;
  const angleInRadians = 0.1*Math.PI/180;
  const wheelTorque = (((((botWeight*gravity*Math.sin(angleInRadians)+5*gravity*0.03*casterWheels+botWeight*accelerationFullLoad)/driveWheels)+((payloadWeight*accelerationFullLoad+(botWeight+payloadWeight-casterWheels*5)*gravity*0.04*Math.cos(angleInRadians)+payloadWeight*gravity*Math.sin(angleInRadians))/2)))*wheelDiameter/2)/0.92;
  const wheelRPM = 60*runSpeed/(Math.PI*wheelDiameter)

  if (wheelRPM >= 0) {
    $('.motor-calculation #wheel-rpm').text(`${Math.round(wheelRPM)} RPM`);
  } else {
    $('.motor-calculation #wheel-rpm').text(`${Math.round(0)} RPM`);
  }

  if (wheelTorque >= 0) {
    $('.motor-calculation #wheel-torque').text(`${Math.round(wheelTorque)} Nm`);
  } else {
    $('.motor-calculation #wheel-torque').text(`${Math.round(0)} Nm`);
  }
}

function checkSectionsForGaps() {
  $('.fcp-section.bg-enabled, .fcp-section.with-image, .fcp-section.image-section.v1, .fcp-section.video-section.v1, .fcp-section.cta-section').each(function () {
    if($(this).next().hasClass('bg-enabled')) {
      $(this).addClass('bg-next');
    }
  });

  $('.fcp-section.bg-enabled, .fcp-section.with-image, .fcp-section.image-section.v1, .fcp-section.video-section.v1').each(function () {
    if($(this).next().hasClass('cta-section')) {
      $(this).addClass('cta-next');
    }
  });
}

function checkMapWidth() {
  let addressWrapperWidth = $('#map-address-wrp').width();
  let mapWrapperWidth = $('.map-wrp').width();

  if (mapWrapperWidth < addressWrapperWidth) {
    return true;
  } else {
    return false;
  }
}

// Cookie Policy Popup
function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
// Cookie Policy Popup

function logoSectionGridSlider() {
  $('.logo-section .logo-grid').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    adaptiveHeight: true,
    watchCSS: true,
  });
}

function logoSectionSlider() {
  $('.logo-section').each(function() {
    if ($('.logo-section .logo-slider').children().length > 6) {
      // Play with this value to change the speed
      let flickity = null;
      const slideshowEl = document.querySelector('.logo-section .logo-slider');

      flickity = new Flickity(slideshowEl, {
        prevNextButtons: true,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        cellAlign:'center',
      });

      flickity.x = 0;
    }
  });
}
